import * as React from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import check from '../../assets/images/check.png'
import {useDispatch, useSelector} from "react-redux";
import {
    LoadingAppSelector,
    changeLoadingState,
    BookSelector,
    changeModalState,
    CustomerSelector
} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

export const FinalBookComponent = () => {
    const {
        loading
    } = useSelector(LoadingAppSelector);
    const {
        startDate,
        isValid, id_room
    } = useSelector(BookSelector);
    const {
        validForm
    } = useSelector(CustomerSelector);
    const location = useLocation();
    let navigate= useNavigate();
    let {
        folio,
        full_name,
        lang
    } = useParams();

    const dispatchApp = useAppDispatch();
    const { t } = useTranslation(namespaces.final_book);
    if (loading) {
        dispatchApp(changeLoadingState())
    }
    useEffect(() => {
        if (!startDate) {
            dispatchApp(changeModalState(true))
        }
    }, []);

    useEffect(() => {
        // @ts-ignore
        const payment = location.state?.payment;
        if (payment) {

        } else {
            if (isValid && validForm) {
                navigate(`../../${lang}/payment`, { replace: true })
            } else {
                if (isValid) {
                    navigate(`../../${lang}/customer`, { replace: true })
                } else {
                    if(id_room && id_room!==''){
                        navigate(`../../${lang}/book/${id_room}`, { replace: true })
                    } else {
                        navigate(`../../${lang}/book/`, { replace: true })
                    }
                }
            }

        }
    }, [location]);

    return (
        <div style={{textAlign: 'center', width: '720px', margin: '35px auto'}}>
            <img style={{width: '55px'}} src={check}/>
            <h4 style={{color: '#007e34'}}>{t('confirmation')} #{folio} {t('booking')}</h4>
            <h2>
                {t('thanks')} {full_name}!
            </h2>
            <div>
                {t('need_info')}
            </div>

        </div>
    );
}
