import React from 'react';
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import {FormControl, MenuItem, Select, Typography} from "@mui/material";
import TextField from '@mui/material/TextField';
import NightsStayIcon from "@mui/icons-material/NightsStay";
import { SelectChangeEvent } from '@mui/material/Select';
import {BookSelector,changeAdultGuest,changeChildGuest} from '../../store/app/app';
import {reset_amount} from '../../store/cart/cart'
import { useSelector, useDispatch } from 'react-redux'
import {RoomSelector} from "../../store/cart/cart";
type Icons = 'adult' | 'child' | 'night';
interface ISelectItem {
    name: Icons,
    title: string,
    isSelect?:boolean,
    max?:number
    min?:number
}
const getIconFromName = (iconName: Icons) => {
    switch (iconName) {
        case 'adult':
            return <EmojiPeopleIcon className="adultos"/>;
        case 'child':
            return <EmojiPeopleIcon className="ninos"/>;
        case 'night':
            return <NightsStayIcon className="adultos"/>
    }
}
export const SelectItem:React.FC<ISelectItem>= ({name,title,isSelect,max,min}) => {
    const Icon = getIconFromName(name);
    const { adult, child, nights} = useSelector(BookSelector);
    const {rooms,amount}=useSelector(RoomSelector);
    const dispatch = useDispatch();
    return (
        <>
            <div className="resumen-item">
                <div className="div-img">
                    {Icon}
                </div>
                <div>
                    <Typography>{title}</Typography>
                </div>
                <div className="border-right">
                    {
                        (isSelect && name==='child') &&  <FormControl sx={{m: 1, minWidth: 80}}  className="select-item">
                            <TextField
                                id="child-input"
                                type="number"
                                value={child}
                                inputProps={{ min:0, max }}
                                onChange={(event)=>{
                                    if(amount?.id!=='') {
                                        dispatch(reset_amount())
                                        dispatch(changeChildGuest(parseInt(event.target.value)))

                                    } else {
                                        dispatch(changeChildGuest(parseInt(event.target.value)))
                                    }
                                }}
                            />
                        </FormControl>

                    }
                    {
                        (isSelect && name==='adult') &&  <FormControl sx={{m: 1, minWidth: 80}}  className="select-item">
                            <TextField
                                id="adult-input"
                                name={'adult'}
                                type="number"
                                value={adult}
                                inputProps={{ min:1, max }}
                                onChange={(event)=>{
                                    if(amount?.id!=='') {
                                        dispatch(reset_amount())
                                        dispatch(changeAdultGuest(parseInt(event.target.value)))

                                    } else {
                                        dispatch(changeAdultGuest(parseInt(event.target.value)))
                                    }
                                }}
                            />
                        </FormControl>
                    }
                    {
                        (name==='night') &&  <FormControl sx={{m: 1, minWidth: 80}}  className="select-item">
                            <div className="contNoches">{nights}</div>
                        </FormControl>

                    }
                </div>
            </div>
        </>
    )
}
