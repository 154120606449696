import * as React from 'react';
import Typography from '@mui/material/Typography';
import {hvm2c} from '../../assets/images'
import {List} from "@mui/material";
import {ListItemIcon} from '../lisItemIcon/listItemIcon';
import {ICardHotel} from '../../store/types'
import { useTranslation } from "react-i18next";
import { namespaces } from "../../i18n/i18n.constants";
export const CardHotel: React.FC<ICardHotel> = ({title, description, listItem,price,price_offer}) => {
    const { t } = useTranslation(namespaces.rooms);
    const pricePerNight=t("price_per_night", { ns: namespaces.cardHotel });
    const selectDateGuest=t("select_date_guests", { ns: namespaces.cardHotel });
    return (
        <>
            <div className="cont-item">
                <div>
                    <img src={hvm2c} alt=""/>
                </div>
                <div className="pd-15">
                    <div className="descripcion-item">
                        <div>
                            <Typography variant={'h6'}>{t(title)}</Typography>
                            <Typography className="txt-item">{description}</Typography>


                        </div>
                        <div className="precios-item">
                            <Typography className="font-15">{price_offer!==0? pricePerNight:selectDateGuest} </Typography>
                            <Typography className="tashed">{price_offer!==0?`$${price_offer}`:''} <small>{price_offer!==0?`us`:''}</small></Typography>
                            <Typography className="fontBold-20">{price!==0?`$${price}`:''} <small>{price!==0?`us`:''}</small></Typography>
                        </div>
                    </div>
                    <div className="D-grid contenido">
                        {listItem.length > 0 && (
                            Array.from({length: Math.ceil(listItem.length / 2)}, (_, i) => (
                                <div key={i}>
                                    <List className="list-item" key={i}>
                                        {
                                            listItem.slice(i * 2, (i + 1) * 2)
                                                .map((value,index) => (
                                                    <ListItemIcon name={value.id}
                                                                  key={index}
                                                                  description={value.name}/>
                                                ))
                                        }
                                    </List>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
