import React from 'react';
import {SelectItem} from "../selectItem/selectItem";
import {List} from "../list/list";
import {TotalSummary} from "../totalSummary/totalSummary";
import {useLocation, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {BookSelector} from "../../store/app/app";
import {RoomsSelector,AmountSelector} from "../../store/cart/cart";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";



export const ReservationDetail = () => {
    let location = useLocation();
    let { room } = useParams();
    const {rooms,room_amount}=useSelector(RoomsSelector);
    const {
       adult,child,nights,total_amount,currency
    } = useSelector(BookSelector);
    const { t } = useTranslation(namespaces.booking);
    const findRoom=room? rooms.find((roomFind: { id: string | undefined,capacity_per_room:number,description:string|null,
        image: string|null,
        include_services:[]
        name: string,
        rooms: number,
        total_capacity: number,
    }) => roomFind.id === room) :null
    return (
        <>

            { room && location.pathname.split('/')[2]==='book'?  <>
                    <SelectItem name={'adult'} title={t('adults')}  max={Math.abs((findRoom?.total_capacity?findRoom?.total_capacity:0)-child)} isSelect/>
                    <SelectItem name={'child'} title={t('childs')}   max={Math.abs((findRoom?.total_capacity?findRoom?.total_capacity:1)-adult)} isSelect/></>:
                <>
                </>
            }
            <List/>
            <TotalSummary currency={currency} total={total_amount}/>
        </>
    );
}
