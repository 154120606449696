import React from 'react';
import {AppBar,Box,Toolbar,Typography,Container} from '@mui/material';
import {Logo} from '../../assets/images'
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {namespaces} from "../../i18n/i18n.constants";
import {changeCurrency} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
export const Menu =() =>{
    const { t,i18n } = useTranslation(namespaces.header);
    const dispatchApp = useAppDispatch();
    return(
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" className="bg-blanco">
                <Container>
                <Toolbar>
                    <div className="contenedor-menu">
                        <div className="contLogo"><img src={Logo} alt=""/></div>
                        <div className="active"><Typography>{t('home')}</Typography></div>
                        <div><Typography>{t('rooms')}</Typography></div>
                        <div><Typography>{t('benefits')}</Typography></div>
                        <div><Typography>{t('Interest')}</Typography></div>
                        <div><Typography>{t('gallery')}</Typography></div>
                        <div><Typography>{t('contact')}</Typography></div>
                        <div onClick={()=>{
                            const lang = i18n?.language==='en'?'es':'en';
                            i18n.changeLanguage(lang).then()
                            const currency=lang==='es'?'MXN':'USD';
                            dispatchApp(changeCurrency(currency))
                        }}><Typography>{i18n?.language==='en'?"Español":"English"}</Typography></div>
                    </div>
                </Toolbar>
                </Container>
            </AppBar>

        </Box>
    )
}
