import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from '../rootReducer'
import {IRoomAvailability,IRoomAmountResponse} from '../types/cart/cart'
import {ICheckout} from '../types/checkout/checkout'
import { changeLoadingState,changeModalState,changeCapacity,changeTotalAmount} from '../app/app'
import {persistReducer} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage'
const api = process.env.REACT_APP_API_URL
interface cartInformation{
    rooms:IRoomAvailability[],
    room_amount:IRoomAmountResponse,
    loading:boolean
}
export const initialState: cartInformation = {
    rooms: [],
    room_amount: {
        id: "",
        name: "",
        description: null,
        image: null,
        total_capacity: 0,
        capacity_per_room: 0,
        rooms: 0,
        price_per_night: 0,
        include_services: [],
        total_amount: 0,
        nights: 0
    },
    loading:true
}

export const getRoomsAvailability = createAsyncThunk<IRoomAvailability[],
    {id:string,currency:string},
    {
        rejectValue: string
    }>(
    'room/availability',
    async (data, thunkApi) => {
        thunkApi.dispatch(changeLoadingState())
        try {
            const response = await axios.get(`${api}/cart/available-rooms`);
            const searchRoom= response.data.find((roomSearch:IRoomAvailability) => roomSearch.id===data.id);
            thunkApi.dispatch(changeLoadingState())
            thunkApi.dispatch(changeCapacity({total_capacity:searchRoom?searchRoom?.total_capacity:0,capacity_per_room:searchRoom?searchRoom?.capacity_per_room:0}))
            const newState=thunkApi.getState();
            // @ts-ignore
            if(searchRoom===undefined && !newState?.app?.modal){
                thunkApi.dispatch(changeModalState(true))
            }
            // @ts-ignore
            if(searchRoom!==undefined && newState?.app?.modal){
                thunkApi.dispatch(changeModalState(false))
            }
            return response.data;
        } catch (err) {
            // thunkApi.dispatch(closeBackdrop());
            const newState=thunkApi.getState();
            // @ts-ignore
            if(searchRoom!==undefined && newState?.app?.modal){
                thunkApi.dispatch(changeModalState(false))
            }
            thunkApi.dispatch(changeLoadingState());

            return thunkApi.rejectWithValue('error')
        }
    }
)

export const roomAmount = createAsyncThunk<IRoomAmountResponse,
    { id: string,entry_date:string,departure_date:string ,adult: number ,child:number,currency:string},
    {
        rejectValue: string
    }>(
    'room/amount',
    async (data, thunkApi) => {
        thunkApi.dispatch(changeLoadingState())
        try {
            const response = await axios.get(`${api}/cart/room-category-price/${data.id}?entry_date=${data.entry_date}&departure_date=${data.departure_date}&adult_quantity=${data.adult}&child_quantity=${data.child}&currency=${data.currency}`);
            // thunkApi.dispatch(closeBackdrop());
            thunkApi.dispatch(changeTotalAmount(response.data.total_amount))
            thunkApi.dispatch(changeLoadingState())
            return response.data;
        } catch (err) {
            thunkApi.dispatch(changeLoadingState())
            // thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const checkout = createAsyncThunk<string,
    {data:ICheckout},
    {
        rejectValue: string
    }>(
    'room/checkout',
    async (data, thunkApi) => {
        thunkApi.dispatch(changeLoadingState())
        try {
            const response = await axios.post(`${api}/cart/checkout/`,data);
            thunkApi.dispatch(changeLoadingState())
            return response.data;
        } catch (err) {
            thunkApi.dispatch(changeLoadingState())
            // thunkApi.dispatch(closeBackdrop());
            return thunkApi.rejectWithValue('error')
        }
    }
)


export const roomSlice = createSlice({
    initialState,
    name: 'room',
    extraReducers: builder => {
        builder
            .addCase(getRoomsAvailability.pending, state => {
                state.loading = true
            })
            .addCase(getRoomsAvailability.fulfilled, (state, {payload}) => {
                state.rooms = payload
                state.loading = false
            })
            .addCase(getRoomsAvailability.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(checkout.pending, state => {
                state.loading = true
            })
            .addCase(checkout.fulfilled, (state, {payload}) => {
                // state.rooms = payload
                state.loading = false
            })
            .addCase(checkout.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(roomAmount.pending, state => {
                state.loading = true
            })
            .addCase(roomAmount.fulfilled, (state, {payload}) => {
                state.room_amount=payload
            })
            .addCase(roomAmount.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {
        reset:(state)=>{
            state=initialState
        },
        reset_amount:(state)=>{
            state.room_amount={
                id: "",
                name: "",
                description: null,
                image: null,
                total_capacity: 0,
                capacity_per_room: 0,
                rooms: 0,
                price_per_night: 0,
                include_services: [],
                total_amount: 0,
                nights: 0
            }
        }
    },
})


export const RoomSelector = (state: RootState) => {
    return {
        rooms: state.room.rooms,
        amount: state.room.room_amount,
        loading:state.room.loading
    };
};
export const RoomsSelector = (state: RootState) => state.room
export const AmountSelector = (state: RootState) => state.room.room_amount
export const LoadingRoomSelector=(state:RootState)=> state.room.loading

// export const RoomReducer = roomSlice.reducer
export const RoomReducer = persistReducer({
    storage:storageSession,
    key: 'room',
    blacklist:['loading']
}, roomSlice.reducer)
export const {
    reset,
    reset_amount,
} = roomSlice.actions;
