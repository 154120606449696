import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {LoadingAppSelector,changeModalState} from '../../store/app/app';
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../store/store";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export const ModalComponent = () => {
    const {
        modal
    } = useSelector(LoadingAppSelector);
    const dispatchApp = useAppDispatch();
    return (
        <Modal
            open={modal}
            onClose={(event, reason)=>console.log(event)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Información
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Seleccione una habitación de nuestro sitio para poder continuar
                </Typography>
                <Button variant="contained"  className="btn-hotel" onClick={()=>{
                    dispatchApp(changeModalState(false))
                    window.location.replace('http://demo.bookingshotelvistadelmar.com/bedrooms.html');
                }}>Seleccionar</Button>
            </Box>

        </Modal>
    );
}