export interface ICustomer {
    name:string,
    last_name:string,
    phone:string,
    email:string,
    country:string,
    city:string,
    state:string
    postal_code:string,
    comment:string
}
export const initialStateCustomer= {
    name:'',
    last_name:'',
    phone:'',
    email:'',
    country:'',
    city:'',
    state:'',
    postal_code:'',
    comment:''
}
