import React, { useState,useEffect} from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker,FocusedInputShape } from 'react-dates';
import moment from 'moment';
import {BookSelector, changeEndDate, changeStartDate, changeNightState, changeAdultGuest} from '../../store/app/app';
import { useSelector, useDispatch } from 'react-redux'
import {RoomSelector} from "../../store/cart/cart";
import {reset_amount} from '../../store/cart/cart';
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
export const MultiDatePicker =() =>{

    const dispatch = useDispatch()
    const [ focusedInput, setFocusedInput ] = useState<FocusedInputShape | null>(null);
    const { startDate,endDate} = useSelector(BookSelector);
    const {rooms,amount}=useSelector(RoomSelector);

    useEffect(() => {
        if(startDate && endDate){
            const start_date = moment(startDate, "YYYY-MM-DD");
            const end_date = moment(endDate, "YYYY-MM-DD");
            const nights = end_date.diff(start_date, 'days');
            dispatch(changeNightState((nights)))
        }
    }, [endDate,startDate]);
    return(
        <>
            <DateRangePicker
                startDate={startDate!==null?moment(startDate):startDate}
                startDateId='daterangepicker_start_date'
                startDateAriaLabel='Fecha de Entrada'
                endDate={endDate!==null?moment(endDate):endDate}
                endDateId='daterangepicker_end_date'
                onDatesChange={(value)=>{
                    // @ts-ignore
                    if (value.startDate!==null && startDate!==value.startDate){
                        if(amount?.id!=='') {
                            dispatch(reset_amount())
                            dispatch(changeStartDate(moment(value.startDate).format('YYYY-MM-DD')))

                        } else {
                            dispatch(changeStartDate(moment(value.startDate).format('YYYY-MM-DD')))
                        }

                    }
                    // @ts-ignore
                    if (value.endDate!==null && endDate!==value.endDate){
                        if(amount?.id!=='') {
                            dispatch(reset_amount())
                            dispatch(changeEndDate(moment(value.endDate).format('YYYY-MM-DD')))

                        } else {
                            dispatch(changeEndDate(moment(value.endDate).format('YYYY-MM-DD')))
                        }

                    }

                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) =>
                {
                    setFocusedInput(focusedInput)
                }
                }
            />
        </>
    )
}
