import React, {useEffect} from 'react';
import Typography from "@mui/material/Typography";
import {Button, FormControl, FormControlLabel} from "@mui/material";
import {LogopayPal} from "../../assets/images";
import Checkbox from "@mui/material/Checkbox";
import {PaymentButton} from '../PaymentButton/PaymentButton'
import {useSelector} from "react-redux";
import {paymentSelector,changeTermCond} from "../../store/payment/payment";
import {useAppDispatch} from "../../store/store";
import {BookSelector, changeModalState, CustomerSelector} from "../../store/app/app";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";


export const Payment:React.FC<any> = () => {
    const {
         isValid, startDate,id_room
    } = useSelector(BookSelector);
    const {validForm} = useSelector(CustomerSelector);
    let navigate= useNavigate();
    let {
        check_term_condition
    } = useSelector(paymentSelector);
    const { t,i18n } = useTranslation(namespaces.payment);
    let { lang } = useParams();
    let location=useLocation();
    useEffect(() => {
        if (startDate===''){
            dispatchApp(changeModalState(true))
        }
    },[]);
    useEffect(() => {
        if(location?.pathname.split('/')[2]==='payment'){
            if (isValid  && validForm){
            } else {
                if (validForm){
                     navigate(`../../${lang}/customer`, { replace: true })
                } else {
                    if(id_room!=='' && id_room){
                           navigate(`../../${lang}/book/${id_room}`, { replace: true })
                    } else {
                        navigate(`../../${lang}/book`, { replace: true })
                    }
                }
            }
        }

    },[isValid,id_room,validForm]);
    useEffect(() => {
        if (validForm){

        } else {
            dispatchApp(changeModalState(true))
        }
    },[]);
    const dispatchApp = useAppDispatch();
    return (
        <>
            <Typography variant="h6">{t('select_complete_reservation')}</Typography>
            <FormControl component="fieldset" className="checkbox">
                <FormControlLabel
                    control={<Checkbox
                        checked={check_term_condition}
                        onChange={(event, checked)=>{
                            dispatchApp(changeTermCond(checked))
                        }}
                    />}
                    label={t('select_complete_reservation')}
                />
            </FormControl>
            <div className="cont-txt-paypal">
                <div>
                    <img className="logoPayPal" src={LogopayPal} alt=""/>
                </div>
                <div>
                    <Typography>{t('paypal')}</Typography>
                </div>
            </div>
            {
                check_term_condition &&    <div className="cont-metodo-pago">
                    <PaymentButton></PaymentButton>
                </div>
            }


        </>
    );
}
