import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../rootReducer'
import {ICheckout} from '../types/checkout/checkout'
import {persistReducer} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage'
export interface IApp {
    book_information: {
        startDate: string | null,
        endDate: string | null,
        adult: number,
        child: number,
        nights: number
        isValid: boolean,
        total_amount:number,
        currency:string
        id_room:string
    },
    form_guest: {
        name: string,
        last_name: string,
        phone: string,
        country: string,
        city: string,
        email:string,
        state: string
        postal_code: string
        comment: string,
        validForm: boolean
    },
    loading:boolean,
    modal:boolean,
    capacity_room:ICapacityRoom
}

interface ICapacityRoom{
    total_capacity:number,
    capacity_per_room:number
}
export const initialState: IApp = {
    book_information: {
        startDate: null,
        endDate: null,
        adult: 1,
        child: 0,
        nights: 0,
        currency:'USD',
        isValid: false,
        total_amount:0,
        id_room:''
    },
    form_guest: {
        name: '',
        last_name: '',
        phone: '',
        email:'',
        country: '',
        city: '',
        state: '',
        postal_code: '',
        comment: '',
        validForm: false
    },
    loading:false,
    modal:false,
    capacity_room:{
        total_capacity:1,
        capacity_per_room:1
    }
}

export const AppSlice = createSlice({
    name: 'App',
    initialState,
    reducers: {
        changeStartDate: (state, {payload}: PayloadAction<string>) => {
            state.book_information.startDate = payload
        },
        changeTotalAmount: (state, {payload}: PayloadAction<number>) => {
            state.book_information.total_amount = payload
        },
        changeIdRoom: (state, {payload}: PayloadAction<string>) => {
            state.book_information.id_room = payload
        },
        changeLoadingState:(state)=>{
            state.loading=!state.loading
        },
        changeModalState:(state,{payload}:PayloadAction<boolean>)=>{
            state.modal=payload
        },
        changeEndDate: (state, {payload}: PayloadAction<string>) => {
            state.book_information.endDate = payload
        },
        changeNightState: (state, {payload}: PayloadAction<number>) => {
            state.book_information.nights = payload
        },
        changeCurrency: (state, {payload}: PayloadAction<string>) => {
            state.book_information.currency = payload
        },
        changeAdultGuest: (state, {payload}: PayloadAction<number>) => {
            state.book_information.adult = payload
        },
        changeChildGuest: (state, {payload}: PayloadAction<number>) => {
            state.book_information.child = payload
        },
        changeTotalCapacity: (state, {payload}: PayloadAction<number>) => {
            state.capacity_room.total_capacity = payload
        },
        changeCapacity: (state, {payload}: PayloadAction<ICapacityRoom>) => {
            const {total_capacity,capacity_per_room}=payload;
            state.capacity_room.total_capacity = total_capacity;
            state.capacity_room.capacity_per_room = capacity_per_room;
        },
        changeValidBook: (state, {payload}: PayloadAction<boolean>) => {
            state.book_information.isValid = payload
        },
        changeFormName: (state, {payload}: PayloadAction<string>) => {
            state.form_guest.name = payload
        },

        changeFormLastName: (state, {payload}: PayloadAction<string>) => {
            state.form_guest.last_name = payload
        },
        changeFormPhone: (state, {payload}: PayloadAction<string>) => {
            state.form_guest.phone = payload
        },
        changeFormCountry: (state, {payload}: PayloadAction<string>) => {
            state.form_guest.country = payload
        },
        changeFormCity: (state, {payload}: PayloadAction<string>) => {
            state.form_guest.city = payload
        },
        changeFormValid: (state, {payload}: PayloadAction<boolean>) => {
            state.form_guest.validForm = payload
        },
        changeFormState: (state, {payload}: PayloadAction<string>) => {
            state.form_guest.state = payload
        },
        changeFormPostalCode: (state, {payload}: PayloadAction<string>) => {
            state.form_guest.postal_code = payload
        },
        changeFormComment: (state, {payload}: PayloadAction<string>) => {
            state.form_guest.comment = payload
        },
        changeFormGuest:(state,{payload}:PayloadAction<ICheckout>)=>{
                state.form_guest.name=payload.name
                state.form_guest.phone=payload.phone
                state.form_guest.last_name=payload.last_name
                state.form_guest.email=payload.email
                state.form_guest.country=payload.country
                state.form_guest.city=payload.city
                state.form_guest.state=payload.state
                state.form_guest.postal_code=payload.postal_code
                state.form_guest.comment=payload.comment
        }
    },
})


export const {
    changeStartDate,
    changeEndDate,
    changeCurrency,
    changeNightState,
    changeAdultGuest,
    changeChildGuest,
    changeValidBook,
    changeFormComment,
    changeFormGuest,
    changeFormState,
    changeLoadingState,
    changeModalState,
    changeCapacity,
    changeTotalAmount,
    changeIdRoom,
    changeFormValid
} = AppSlice.actions


export const AppReducer = persistReducer({
    storage:storageSession,
    key: 'App',
    whitelist:["book_information","form_guest"],
    blacklist:['modal','loading','currency'],
}, AppSlice.reducer)

export const BookSelector = (state: RootState) => state.app.book_information
export const CustomerSelector = (state: RootState) => state.app.form_guest
export const LoadingAppSelector=(state:RootState)=>state.app
