import { combineReducers } from '@reduxjs/toolkit';
import {AppReducer} from './app/app'
import {RoomReducer} from './cart/cart';
import {PaymentReducer} from './payment/payment'

export const rootReducer = combineReducers({
    app:AppReducer,
    room:RoomReducer,
    payment:PaymentReducer
})

export type RootState = ReturnType<any>

export default rootReducer
