import React from 'react';
import {PayPalButton} from 'react-paypal-button-v2';
import {useSelector} from "react-redux";
import {paymentSelector} from "../../store/payment/payment";
import {changeLoadingState, LoadingAppSelector} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
import {changeIdPayment} from '../../store/payment/payment'
import {useNavigate, useParams} from "react-router-dom";
const api = process.env.REACT_APP_API_URL
export const PaymentButton = () => {

    let navigate = useNavigate();
    let {
        data
    } = useSelector(paymentSelector);
    const {
        loading
    } = useSelector(LoadingAppSelector);
    const dispatchApp = useAppDispatch();
    let { lang } = useParams();
    const createOrder = () => {

        return fetch(`${api}/cart/checkout`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((order) => {
                dispatchApp(changeIdPayment(order.id))
                return order.id
            })
            .catch((err) => {

            });
    };
    const onApprove = (data: any) => {
        dispatchApp(changeLoadingState())
        const {orderID} = data
        return fetch(`${api}/cart/checkout/capture/${orderID}`, {
            method: "PUT",
            body: JSON.stringify({
                order_id: orderID,
            }),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((details) => {

                const {
                    folio,
                    full_name,
                } = details;
                dispatchApp(changeLoadingState())
                navigate(`../../${lang}/final-book/${folio}/${full_name}/`, {replace: true,state:{payment:true}})
                window.sessionStorage.clear();
            })
            .catch((err) => {

            });


    }
    // @ts-ignore
    return (
        <div>

            <PayPalButton
                createOrder={() => createOrder()}
                onError={()=> {
                    if(loading){
                        dispatchApp(changeLoadingState())
                    }

                }}
                catchError={()=>{console.log('catch error')}}
                onApprove={(data: any) => {
                    onApprove(data)
                }
                }
            />
        </div>
    );
};
