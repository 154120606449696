export const namespaces = {
    header: "header",
    booking: "booking",
    tab_tittles: "tab_tittles",
    tab_contain: "tab_contain",
    rooms:"rooms",
    cardHotel:"cardHotel",
    form:'form',
    payment:'payment',
    final_book:'final_book',
    modal_id_not_found:'modal_id_not_found'
};

export const languages = {
    es: "es",
    en: "en",
};