import React, {useEffect} from 'react';
import {MultiDatePicker} from '../multiDatePicker/multiDatePicker';
import {ReservationDetail} from '../reservationDetail/reservationDetail';
import Button from '@mui/material/Button';
import {checkIn, checkOut} from "../../assets/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {BookSelector, changeValidBook,changeIdRoom} from "../../store/app/app";
import {AmountSelector, roomAmount} from '../../store/cart/cart'
import { useParams } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

export const FindRoom = () => {
    let location = useLocation();
    const Dispatch = useDispatch();
    let navigate = useNavigate();
    const { t,i18n } = useTranslation(namespaces.booking);
    const {
        isValid, startDate, endDate, adult,child,nights,id_room,currency
    } = useSelector(BookSelector);

    let { room,lang } = useParams();

    const {
        id
    } = useSelector(AmountSelector);
    useEffect(() => {
                if(room!=='' && room){
                    // @ts-ignore
                    Dispatch(changeIdRoom(room))
                }


    }, [room]);
    useEffect(() => {
    }, [lang]);
    useEffect(() => {
        if (startDate && endDate && nights!==0) {
            Dispatch(changeValidBook(true))
        } else {
            if (isValid){
                Dispatch(changeValidBook(false))
            }
        }
    }, [endDate, startDate,nights]);
    useEffect(() => {
        if (localStorage.getItem("persist:App")) {
           localStorage.clear();
        } else {}
        if (localStorage.getItem("persist:Payment")) {
            //localStorage.clear();
        } else {}
        if (localStorage.getItem("persist:room")) {
            localStorage.clear();
        } else {}
    }, []);
    useEffect(() => {
        const currencyRoute=lang==='es'?'MXN':'USD';
        if(location?.pathname.split('/')[2]==='/customer'||location?.pathname.split('/')[2]==='/payment'){
            if (startDate && endDate&& id_room!=='') {
                // @ts-ignore
                Dispatch(roomAmount({id:id_room,entry_date:startDate,departure_date:endDate,adult:adult,child:child,currency:currencyRoute}))
            }
        } else {
            if (startDate && endDate&& id_room!=='') {
                // @ts-ignore
                Dispatch(roomAmount({id:id_room,entry_date:startDate,departure_date:endDate,adult:adult,child:child,currency:currencyRoute}))
            }
        }
    }, [endDate, startDate,adult,child,room,id_room,lang]);
    return (
        <>
            <div className="D-grid">
                <div className="icon-calendar">
                    <img src={checkIn} alt=""/>
                    <p>{t('entry_date')}</p>
                </div>
                <div className="icon-calendar">
                    <img src={checkOut} alt=""/>
                    <p>{t('departure_date')}</p>
                </div>
            </div>
            <MultiDatePicker/>

            <ReservationDetail/>
            {
                (location.pathname.split('/')[2] === 'book' && (startDate!==null && endDate!==null)) ? <Button
                    onClick={() => {
                       navigate(`../../${lang}/customer`, {replace: true,state:{id:room}})
                    }}
                    disabled={!isValid}
                    variant="contained"
                    className="btn-hotel">
                    {i18n?.language==='en'?"Continue":"Continuar"}</Button> : <></>
            }

        </>
    );
}
