import React from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import BedIcon from "@mui/icons-material/Bed";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import KitchenIcon from "@mui/icons-material/Kitchen";
import LockIcon from "@mui/icons-material/Lock";
import AirIcon from "@mui/icons-material/Air";
import {IListItemCard} from "../../store/types"
const getIconFromName = (iconName: string) => {
    switch (iconName) {
        case 'dedd1f77-4b42-4cd4-a0a1-5a90008df503':
            return  <Avatar className="avatar-icons"><BedIcon /></Avatar>;
        case 'e4b6dd5a-010e-45c3-beff-938311fce09e':
            return <Avatar className="avatar-icons"><RestaurantIcon /></Avatar>;
        case '5bb9189b-8c25-422b-84a8-f174ca439587':
            return <Avatar className="avatar-icons"><KitchenIcon /></Avatar>;
        case 'e395443b-f2e3-4e39-9441-7ed2bf670383':
            return <Avatar className="avatar-icons"><LockIcon /></Avatar>;
        case '3ecb5676-6ca9-4549-a9b3-089f340e31f9':
            return  <Avatar className="avatar-icons"><AirIcon /></Avatar>;
        default:
            return  <Avatar className="avatar-icons"><BedIcon /></Avatar>;
    }
}

export const ListItemIcon:React.FC<IListItemCard> = ({name,description}) => {
    const Icon = getIconFromName(name);
    return (
        <ListItem>
            <ListItemAvatar>
                {Icon}
            </ListItemAvatar>
            <ListItemText primary={`${description}`}/>
        </ListItem>
    );
}
