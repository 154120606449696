import { namespaces } from "./i18n.constants";

export const en = {
    [namespaces.header]: {
        home: "Home",
        rooms: "Rooms",
        benefits: "Benefits",
        Interest: "Interest",
        gallery: "Gallery",
        contact: "Contact",
        lang: "Spanish",
    },
    [namespaces.booking]: {
        entry_date: "Entry date",
        departure_date: "Departure date",
        adults: "Adults",
        childs: "Childs",
        nights: "Nights",
        Rooms: "Rooms",
    },
    [namespaces.tab_tittles]:{
        select_your_room:"Select your room",
        contact_information:"Contact information",
        payment_process:"Payment process"
    },
    [namespaces.rooms]:{
        "Standard Room 2 Beds":"Standard Room 2 Beds",
        "Standard Room 1 Bed":"Standard Room 1 Bed",
        "Sea View Room 2 Beds":"Sea View Room 2 Beds",
        "Sea View Room 1 Bed":"Sea View Room 1 Bed",
        "Junior Suite Room":"Junior Suite Room",
    },
    [namespaces.cardHotel]:{
        price_per_night:"price per night up to",
        select_date_guests:"Select date and guests",
    },
    [namespaces.form]: {
        name:"Name",
        last_name:"Lastname",
        phone: "Phone",
        email: "Email",
        country: "Country",
        city: "City",
        state: "State",
        postal_code: "Postal Code",
        enter_your_data: "Enter your data",
        requests_comments: "Request / Comments",
        required: "Required",
        email_invalid: "Email invalid",
    },
    [namespaces.payment]: {
        method:"How do you want to pay?",
        select_complete_reservation:"By selecting complete reservation, I acknowledge that I have read and accept the Rules and Restrictions, Terms of Use and Privacy Terms.",
        paypal: "PayPal allows you to send payments quickly and securely online with a credit card or bank account.",
    },
    [namespaces.final_book]: {
        thanks:"Thank you for your reservation",
        confirmation:"Confirmation",
        booking:"Booking",
        need_info:"If you have any questions or clarifications about your reservation, you can call us at the following number 987 872 0545 / USA Tool free 1-888-309-9988 and we will gladly assist you.",
    },
    [namespaces.modal_id_not_found]: {
        information:"Information",
        select_room:"Select a room from our website to continue",
        selection:"Select",
    },
};

export const es = {

    [namespaces.header]: {
        home: "Inicio",
        rooms: "Habitaciones",
        benefits: "Beneficios",
        Interest: "Interés",
        gallery: "Galería",
        contact: "Contacto",
        lang: "Ingles",
    },
    [namespaces.booking]: {
        entry_date: "Fecha de entrada",
        departure_date: "Fecha de salida",
        adults: "Adultos",
        childs: "Niños",
        nights: "Noches",
        Rooms: "Cuartos",
    },
    [namespaces.tab_tittles]:{
        select_your_room:"Seleccione su habitación",
        contact_information:"Información de contacto",
        payment_process:"Proceso de pago"
    },
    [namespaces.rooms]:{
        "Standard Room 2 Beds":"Habitación Estándar 2 Camas",
        "Standard Room 1 Bed":"Habitación Estándar 1 Cama",
        "Sea View Room 2 Beds":"Habitación Vista Del Mar 2 Camas",
        "Sea View Room 1 Bed":"Habitación Vista Del Mar 1 Cama",
        "Junior Suite Room":"Habitación Junior Suite",
    },
    [namespaces.cardHotel]:{
        price_per_night:"Precio por noche de hasta",
        select_date_guests:"Seleccione fecha y huespedes",
    },
    [namespaces.form]: {
        name:"Nombre",
        last_name:"Apellido",
        phone: "Teléfono",
        email: "Correo Electrónico",
        country: "País",
        city: "Ciudad",
        state: "Estado",
        postal_code: "Código Postal",
        enter_your_data: "Ingresa tus datos",
        requests_comments: "Peticiones / Comentarios",
        required: "Requerido",
        email_invalid: "Correo Electrónico Invalido",
    },
    [namespaces.payment]: {
        method:"¿Cómo quieres pagar?",
        select_complete_reservation:"Al seleccionar completar reservacion, reconozco que he leído y acepto las Reglas y Restricciones, Condiciones de Uso y Condiciones de privacidad.",
        paypal: "PayPal permite enviar pagos rápidamente y con seguridad en línea con una tarjeta de crédito o una cuenta bancaria.",
    },
    [namespaces.final_book]: {
        thanks:"¡Gracias por su Reserva",
        confirmation:"Confirmación",
        booking:"de Reserva",
        need_info:" Si tiene alguna duda o aclaración de su reserva, puede llamarnos al siguiente número 987 872 0545 / USA Tool free 1-888-309-9988 y con gusto le atenderemos.",
    },
    [namespaces.modal_id_not_found]: {
        information:"Información",
        select_room:"Seleccione una habitación de nuestro sitio para poder continuar",
        selection:"Seleccionar",
    },
};